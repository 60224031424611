.lc-teaser--image {
  padding: 0;
  position: relative;

  .lc-teaser--wrapper & {
    background-color: var(--global-color-white-100);

    @include sm-min {
      height: 100%;
    }
  }
}
