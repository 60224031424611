$font-settings-title-l: (
  null: (2.6rem, 3.2rem),
  sm: (3.6rem, 4.2rem),
  xxl: (5.4rem, 5.7rem)
) !default;

$font-settings-title-s: (
  null: (2rem, 2.6rem),
  sm: (2.8rem, 3.5rem),
  xxl: (2.8rem, 3.5rem)
) !default;

$font-settings-teaser-50-50: (
  null: (2rem, 2.6rem),
  sm: (2.2rem, 2.8rem),
  xxl: (2.6rem, 3.2rem)
) !default;

$font-settings-teaser-50-100: (
  null: (2rem, 2.6rem),
  sm: (2.2rem, 2.8rem),
  xxl: (3.2rem, 4.1rem)
) !default;

.lc-teaser {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100dvh - 70px);
  padding: var(--global-space-m) var(--global-space-m) 134px;
  position: relative;
  width: 100%;

  @include sm-min {
    height: auto;
    min-height: calc(50dvh - 50px);
    padding: var(--global-space-l) var(--global-space-l) 166px;
  }

  @include lg-min {
    min-height: calc(100dvh - 100px);
    padding: var(--global-space-l) var(--global-space-xl) 166px;
    width: 50%;

    .lc-teaser--wrapper & {
      height: 100%;
      width: 100%;
    }
  }

  .lc-questions-item & {
    padding: var(--global-space-m) var(--global-space-m) 88px;

    @include sm-min {
      padding: var(--global-space-l) var(--global-space-l) 122px;
    }

    @include lg-min {
      padding: var(--global-space-l) var(--global-space-xl) 122px;
    }
  }

  &.background-gradient {
    &::after {
      background: linear-gradient(90deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 100%);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &.lc-teaser--contact {
    padding: var(--global-space-m) var(--global-space-m) 90px;

    @include sm-min {
      padding: var(--global-space-l) var(--global-space-l) 124px;
    }

    @include lg-min {
      padding: var(--global-space-l) var(--global-space-xl) 124px;
    }
  }

  &.lc-grid__height--corrected {
    height: auto !important;

    @include lg-max {
      @include sm-min {
        width: 100% !important;
      }
    }
  }

  &.lc-grid__height--50 {
    min-height: calc(50dvh - 35px);

    @include sm-min {
      min-height: calc(50dvh - 50px);
      width: 50%;
    }

    @include lg-min {
      min-height: calc(50dvh - 50px);
    }
  }

  &.lc-grid__width--25 {
    width: 100%;

    @include sm-min {
      width: 50%;
    }

    @include lg-min {
      width: 25%;
    }
  }

  &.lc-grid__width--100 {
    width: 100%;
  }

  &.lc-teaser--link-bottom {
    padding-bottom: 84px;
  }

  &.partner-hide {
    display: none !important;
  }

  &.image-small {
    background-color: var(--global-color-white-100);
  }

  &.lc-icon__bottom--nswownspublish::after,
  &.lc-icon__bottom--nswow::after,
  &.lc-icon__bottom--nspublish::after {
    background-image: url('#{$svg-path}/icon-nspublish-black.min.svg');
    background-repeat: no-repeat;
    background-size: auto 24px;
    bottom: var(--global-space-l);
    content: "";
    display: block;
    height: 23px;
    position: absolute;
    right: var(--global-space-m);
    width: 104px;

    @include sm-min {
      background-size: auto 30px;
      height: 29px;
      right: var(--global-space-l);
      width: 132px;
    }

    @include lg-min {
      right: var(--global-space-xl);
    }
  }

  &.lc-icon__bottom--nswow::after {
    background-image: url('#{$svg-path}/icon-nswow-black.min.svg');
    background-size: auto 16px;
    height: 18px;
    width: 80px;

    @include sm-min {
      background-size: auto 20px;
      height: 22px;
      width: 100px;
    }
  }
  
  &.lc-icon__bottom--nswownspublish::after {
    background-image: url('#{$svg-path}/icon-nswownspublish-black.min.svg');
    background-size: auto 16px;
    height: 18px;
    width: 80px;

    @include sm-min {
      background-size: auto 20px;
      height: 22px;
      width: 100px;
    }
  }

  &.lc-component--background-blue.lc-icon__bottom--nspublish::after,
  &.lc-component--background-orange.lc-icon__bottom--nspublish::after,
  &.lc-component--background-darkgrey.lc-icon__bottom--nspublish::after {
    background-image: url('#{$svg-path}/icon-nspublish-white.min.svg');
  }

  &.lc-component--background-blue.lc-icon__bottom--nswow::after,
  &.lc-component--background-orange.lc-icon__bottom--nswow::after,
  &.lc-component--background-darkgrey.lc-icon__bottom--nswow::after {
    background-image: url('#{$svg-path}/icon-nswow-white.min.svg');
  }
}

.lc-teaser:not(.lc-grid__height--50):not(.lc-grid__width--100):not(.lc-grid__width--25) +
.lc-teaser.lc-grid__height--50:not(.lc-grid__width--25):not(.lc-teaser--wrapper) +
.lc-teaser.lc-grid__height--50:not(.lc-grid__width--25):not(.lc-teaser--wrapper),
.lc-teaser:not(.lc-grid__height--50):not(.lc-grid__width--100):not(.lc-grid__width--25) +
.lc-teaser.lc-grid__height--50.lc-teaser--wrapper:not(.lc-grid__width--25) +
.lc-teaser.lc-grid__height--50.lc-teaser--wrapper:not(.lc-grid__width--25) {
  height: 100%;

  @include lg-min {
    margin-left: 50%;
    margin-top: calc(-1 * (50dvh - 50px));
  }
}

.lc-teaser:not(.lc-grid__height--50):not(.lc-grid__width--100):not(.lc-grid__width--25) +
.lc-teaser.lc-grid__height--50.lc-grid__width--25 {
  height: 100%;
}

.lc-teaser:not(.lc-grid__height--50):not(.lc-grid__width--100):not(.lc-grid__width--25) +
.lc-teaser.lc-grid__height--50.lc-grid__width--25 +
.lc-teaser.lc-grid__height--50.lc-grid__width--25  {
  height: 100%;
}

.lc-teaser:not(.lc-grid__height--50):not(.lc-grid__width--100):not(.lc-grid__width--25) +
.lc-teaser.lc-grid__height--50.lc-grid__width--25 +
.lc-teaser.lc-grid__height--50.lc-grid__width--25 +
.lc-teaser.lc-grid__height--50.lc-grid__width--25,
.lc-teaser:not(.lc-grid__height--50):not(.lc-grid__width--100):not(.lc-grid__width--25) +
.lc-teaser.lc-grid__height--50:not(.lc-grid__width--100):not(.lc-grid__width--25) +
.lc-teaser.lc-grid__height--50.lc-grid__width--25 {
  height: 100%;

  @include lg-min {
    margin-left: 50%;
    margin-top: calc(-1 * (50dvh - 50px));
  }
}

.lc-teaser:not(.lc-grid__height--50):not(.lc-grid__width--100):not(.lc-grid__width--25) +
.lc-teaser.lc-grid__height--50.lc-grid__width--25 +
.lc-teaser.lc-grid__height--50.lc-grid__width--25 +
.lc-teaser.lc-grid__height--50.lc-grid__width--25 +
.lc-teaser.lc-grid__height--50.lc-grid__width--25,
.lc-teaser:not(.lc-grid__height--50):not(.lc-grid__width--100):not(.lc-grid__width--25) +
.lc-teaser.lc-grid__height--50:not(.lc-grid__width--100):not(.lc-grid__width--25) +
.lc-teaser.lc-grid__height--50.lc-grid__width--25 +
.lc-teaser.lc-grid__height--50.lc-grid__width--25 {
  height: 100%;

  @include lg-min {
    margin-top: calc(-1 * (50dvh - 50px));
  }
}

.lc-teaser__content {
  height: 100%;
  position: relative;
  z-index: 2;

  .lc-teaser--project & {
    @include flexbox($direction: column, $wrap: nowrap);
  }

  .lc-teaser--keyfigure & {
    @include flexbox($direction: column, $wrap: nowrap);
  }

  .lc-teaser--image-video & {
    @include flexbox($direction: column, $wrap: nowrap, $align-content: normal);

    @include sm-min {
      padding-bottom: var(--global-space-xl);
    }
  }

  .lc-teaser--image-video:not(.image-right) & {
    padding-bottom: var(--global-space-l);
  }
}

.lc-teaser__title {
  font-weight: var(--global-font-weight-book);
  @include font-size($font-settings-title-l);

  .lc-grid__width--25 &,
  .lc-teaser--wrapper & {
    @include font-size($font-settings-title-s);
  }

  .lc-grid__width--100 &,
  .lc-teaser--image-video & {
    @include lg-min {
      max-width: calc(59.8% + var(--global-space-xl));
      width: calc(59.8% + var(--global-space-xl));
    }
  }
}

.lc-teaser__title--orange {
  color: var(--global-color-primary-100);

  .lc-component--background-orange & {
    color: var(--global-color-black-100);
  }
}

.lc-teaser__title--bw {
  color: var(--global-color-black-100);

  .lc-component--background-blue &,
  .lc-component--background-orange &,
  .lc-component--background-darkgrey & {
    color: var(--global-color-white-100);
  }
}

.lc-teaser__title--span {
  display: block;
  word-break: break-word;
}

.lc-teaser__paragraph {
  @include font-size($font-settings-teaser-50-100);

  .font-infotext & {
    @include font-size($font-settings-teaser-50-50);
  }

  .lc-grid__height--50 & {
    @include font-size($font-settings-teaser-50-50);
  }

  .lc-grid__width--25.font-infotext & {
    @include font-size($font-settings-teaser-50-50);
  }

  .lc-grid__width--25 &,
  .lc-teaser--wrapper & {
    @include font-size($font-settings-paragraph);
  }

  .lc-grid__width--100 &,
  .lc-teaser--image-video & {
    @include lg-min {
      max-width: calc(59.8% + var(--global-space-xl));
      width: calc(59.8% + var(--global-space-xl));
    }
  }
}

.lc-teaser__paragraph,
.lc-teaser__mail {
  .lc-teaser--image-video:not(.image-right) & {
    display: none;
  }

  .lc-teaser--contact & {
    font-weight: var(--global-font-weight-semilight);
    @include font-size($font-settings-teaser-50-50);
  }
}

.lc-teaser__images {
  display: none;

  .show-image-container & {
    gap: 40px;
    @include flexbox($align-content: center);
  }
}

.lc-teaser__button {
  bottom: -102px;
  gap: var(--global-space-m);
  position: absolute;
  @include flexbox;

  @include sm-min {
    bottom: -134px;
    gap: var(--global-space-l);
  }

  .lc-teaser--link-bottom & {
    bottom: -52px;
  }

  .lc-questions-item & {
    bottom: -58px;

    @include sm-min {
      bottom: -90px;
    }
  }

  .lc-teaser--image-video:not(.image-right) & {
    bottom: 0;
    position: relative;
  }
}
