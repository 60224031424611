@include sm-min {
  @include lg-max {
    .lc-teaser:not(.lc-teaser--keyfigure) +
    .lc-teaser--keyfigure +
    .lc-teaser--keyfigure +
    .lc-teaser--keyfigure +
    .lc-teaser--keyfigure +
    .lc-teaser--keyfigure {
      margin-left: 50%;
    }

    .lc-teaser:not(.lc-teaser--keyfigure) +
    .lc-teaser--keyfigure +
    .lc-teaser--keyfigure +
    .lc-teaser--keyfigure +
    .lc-teaser--keyfigure +
    .lc-teaser--keyfigure +
    .lc-teaser--keyfigure {
      margin-left: -100%;
      margin-right: 50%;
    }
  }
}

// @include sm-max {
//   :not(.lc-teaser--keyfigure) +
//   .lc-component--background-blue +
//   .lc-component--background-sand-60 +
//   .lc-component--background-sand-60,
//   :not(.lc-teaser--keyfigure) +
//   .lc-component--background-blue +
//   .lc-component--background-sand-60 +
//   .lc-component--background-sand-60 +
//   .lc-component--background-blue +
//   .lc-component--background-sand-60 {
//     background-color: var(--global-color-secondary-100);
//     color: var(--global-color-white-100);
//   }

//   :not(.lc-teaser--keyfigure) +
//   .lc-component--background-blue +
//   .lc-component--background-sand-60 +
//   .lc-component--background-sand-60 +
//   .lc-component--background-blue,
//   :not(.lc-teaser--keyfigure) +
//   .lc-component--background-blue +
//   .lc-component--background-sand-60 +
//   .lc-component--background-sand-60 +
//   .lc-component--background-blue +
//   .lc-component--background-sand-60 +
//   .lc-component--background-blue {
//     background-color: var(--global-color-sand-60);

//     .lc-teaser__title--orange {
//       color: var(--global-color-primary-100);
//     }

//     .lc-teaser__title--bw {
//       color: var(--global-color-white-100);
//     }
//   }
// }

.lc-teaser__title--bw {
  color: var(--global-color-black-100);

  .lc-component--background-blue &,
  .lc-component--background-orange &,
  .lc-component--background-darkgrey & {
    color: var(--global-color-white-100);
  }
}

.lc-teaser--keyfigure {
  padding: var(--global-space-xl) var(--global-space-xl) var(--global-space-l);

  @include sm-min {
    padding: var(--global-space-xxl) var(--global-space-xl) var(--global-space-l);
  }
}
