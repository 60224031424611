.iz-cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--global-color-white-100);
  z-index: 40;
  box-shadow: 0 -5px 20px rgba(0,0,0,.05);

  @include sm-max {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

.iz-cookies__inner {
  padding: 20px 0 32px;
  width: calc(100% - (2 * var(--global-space-m)));
  @include margin-lr(auto);

  @include xs-min() {
    width: calc(100% - (2 * var(--global-space-l)));
  }

  @include sm-min() {
    padding: 32px 0;
    width: calc(100% - (2 * var(--global-space-xl)));
  }

  @include xl-min() {
    max-width: 100%;
  }
}

.iz-cookies__content {
  width: 100%;
  @include ld-margin-bottom(var(--global-space-s));

  @include sm-min() {
    @include ld-margin-bottom(var(--global-space-l));
    @include flexbox($justify-content: space-between, $align-content: flex-start)
  }
}

.iz-cookies__content--top {
  align-items: flex-end;

}

.iz-cookies__right,
.iz-cookies__left {
  width: 100%;

  @include md-min() {
    width: calc(50% - 30px);
  }
}

.iz-cookies__left {
  @include ld-margin-bottom(var(--global-space-m));

  @include sm-max {
    @include ld-margin-bottom(var(--global-space-s));
  }

  @include md-min() {
    @include ld-margin-bottom(var(--global-space-reset));
  }
}

.iz-cookies__right {
  @include sm-min() {
    @include flexbox($justify-content: space-between, $align-content: flex-end)
  }
}


.iz-cookies__general,
.iz-cookies__marketing {
  @include sm-min() {
    width: calc(50% - 30px);
  }
}

.iz-cookies__general {
  @include ld-margin-bottom(var(--global-space-s));

  @include sm-min() {
    @include ld-margin-bottom(var(--global-space-reset));
  }
}

.iz-cookies__title {
  @include ld-margin-bottom(var(--global-space-s));
  @include flexbox($justify-content: space-between, $align-content: center);

  @include sm-max {
    @include ld-margin-bottom(var(--global-space-xs));
  }
}

.iz-cookies__title-text {
  font-weight: var(--global-font-weight-book);
  width: calc(100% - 66px);
}

.iz-cookies__button-group {
  gap: var(--global-space-xs);
  @include flexbox;

  @include sm-min {
    gap: var(--global-space-m);
  }

  &:first-child {
    margin-bottom: var(--global-space-xs);

    @include sm-min {
      margin-bottom: var(--global-space-m);
    }
  }
}

.iz-cookies__button {
  @include sm-max {
    padding: 6px var(--global-space-s);
  }
}

.iz-form__input--toggle {
  display: none;

  &:checked + .iz-form__toggle {
    &:before {
      background: var(--global-color-primary-100);
    }

    &:after {
      left: 27px;
    }
  }
}

.iz-form__toggle {
  width: 50px;
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:before {
    content: "";
    background: var(--global-color-black-20);
    height: 28px;
    width: 100%;
    display: block;
    border-radius: 14px;
    transition: background 0.4s ease;

    @media (prefers-reduced-motion: reduce) {
      transition-duration: 0s;
    }
  }

  &:after {
    content: "";
    background: var(--global-color-white-100);
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 5px;
    transition: left 0.4s ease;
    @include size(18px);

    @media (prefers-reduced-motion: reduce) {
      transition-duration: 0s;
    }
  }
}

