
$font-settings-footnote: (
  null: (1.2rem, 1.8rem),
  sm: (1.4rem, 2rem),
  xxl: (1.3rem, 1.8rem)
) !default;

.lc-footnote__inner {
  @include flexbox($wrap: nowrap);
}

.lc-footnote__number,
.lc-footnote__text {
  color: var(--global-color-black-60);
  @include font(var(--global-body-font-family), var(--global-body-font-weight-light));
  @include font-size($font-settings-footnote);
}

.lc-footnote__number {
  min-width: var(--global-space-l);
  width: var(--global-space-l);
}

.lc-footnote__text {
  flex-shrink: 1;
}
