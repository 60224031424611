.lc-video__thumbnail,
.lc-video__video {
  .lc-teaser__video & {
    height: 100%;
  }
}

.lc-video__thumbnail {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;

  .lc-teaser__video & {
    background-position: left;
    background-size: contain;
  }
}

.lc-video__video {
  padding: 56.25% 0 0;
  position: relative;

  .lc-teaser:not(.lc-teaser--image-video.image-right) & {
    padding: 0;
  }

  .lc-teaser--image-video & {
    @include flexbox($direction: column, $justify-content: flex-end, $align-content: flex-end);
  }

  .lc-teaser--image-video.image-right & {
    position: relative;
    @include flexbox;
  }

  video,
  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

.lc-video,
.lc-video__video {
  @include sm-max {
    position: static;
  }
}

.lc-video__inner {
  position: relative;
}

.lc-video__thumbnail-container {
  cursor: pointer;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 10;

  .lc-teaser--image-video:not(.image-right) & {
    z-index: 0;
  }
}

.lc-video__play {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.lc-video__play-svg {
  color: var(--global-color-white-60);
  height: 64px;
  transition: .2s ease-in-out;
  width: 64px;

  .lc-video__thumbnail-container:hover & {
    color: var(--global-color-white-100);
  }
}

