.lc-hero {
  background-image: var(--large-url) !important;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include lg-min {
    background-image: var(--xlarge-url) !important;
  }

  &.lc-icon__bottom--nswow::after,
  &.lc-icon__bottom--nspublish::after {
    background-image: url('#{$svg-path}/icon-nspublish-white.min.svg');
    background-repeat: no-repeat;
    background-size: auto 28px;
    bottom: var(--global-space-xl);
    content: "";
    display: block;
    height: 28px;
    position: absolute;
    left: var(--global-space-m);
    width: 119px;
    z-index: 20;

    @include sm-min {
      background-size: auto 34px;
      background-position-x: 1px;
      height: 34px;
      left: var(--global-space-l);
      width: 144px;
    }

    @include lg-min {
      left: var(--global-space-xl);
    }
  }

  &.lc-icon__bottom--nswow::after {
    background-image: url('#{$svg-path}/icon-nswow-white.min.svg');
    background-size: auto 18px;
    height: 23px;
    width: 89px;

    @include sm-min {
      background-size: auto 22px;
      background-position-x: 0;
      height: 28px;
      width: 111px;
    }
  }

  &.lc-component--background-sand.lc-icon__bottom--nspublish::after {
    background-image: url('#{$svg-path}/icon-nspublish-black.min.svg');
  }

  &.lc-component--background-sand.lc-icon__bottom--nswow::after {
    background-image: url('#{$svg-path}/icon-nswow-black.min.svg');
  }

  .lc-hero-slider & {
    height: 100%;
    width: 100vw;
  }

  &.background-gradient {
    &::before {
      background: linear-gradient(90deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 100%);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &::before {
    .background-gradient & {
      background: linear-gradient(90deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 100%);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.lc-hero__video {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  iframe,
  video {
    height: 100%;
    object-fit: cover;
    width: 100%;

    @include lg-max {
      display: none !important;
      visibility: hidden;
      width: 0;
      height: 0;
      overflow: hidden;  
    }
  }
}

.lc-hero__content {
  height: 100%;
  padding: 102px var(--global-space-m) 110px;
  position: relative;
  z-index: 10;

  @include sm-min {
    padding: 195px var(--global-space-l) 284px;
  }

  @include lg-min {
    max-width: 66.6666666%;
    padding: 195px var(--global-space-xl) 390px;
  }

  .lc-hero-slider & {
    min-height: 100dvh;
  }
}

.lc-hero__buttons {
  bottom: var(--global-space-xxl);
  gap: var(--global-space-m);
  position: absolute;
  @include flexbox;

  @include sm-min {
    gap: var(--global-space-l);
  }

  @include lg-min {
    bottom: 185px;
  }
}

.lc-hero__buttons--filter {
  column-gap: var(--global-space-m);
  row-gap: var(--global-space-s);

  @include sm-min {
    column-gap: var(--global-space-l);
    row-gap: var(--global-space-m);
  }
}

.lc-hero__scroll {
  bottom: var(--global-space-m);
  color: var(--global-color-white-100);
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
  transition: .2s ease-in-out;
  position: absolute;
  opacity: .6;
  z-index: 20;

  &:hover {
    opacity: .8;
  }

  @include sm-max {
    display: none;
  }

  .lc-hero-slider & {
    bottom: var(--global-space-l);
    height: 42px;
    left: var(--global-space-l);
    transform: none;

    @include lg-min {
      left: var(--global-space-xl);
    }
  }
}

.lc-hero__tooltip {
  bottom: 8px;
  color: var(--global-color-white-100);
  display: block;
  font-weight: var(--global-font-weight-semilight);
  left: 56px;
  opacity: 0;
  position: absolute;
  transition: opacity 0.3s;
  white-space: nowrap;
  visibility: hidden;



  .lc-hero__scroll:hover & {
    visibility: visible;
    opacity: 1;
  }
}
