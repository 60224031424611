$font-settings-button: (
  null: (2rem, 2.2rem),
  sm: (2.2rem, 2.4rem),
  xxl: (2.6rem, 2.8rem)
) !default;

$font-settings-button-link: (
  null: (1.8rem, 2.4rem),
  sm: (1.8rem, 2.4rem),
  xxl: (1.8rem, 2.4rem)
) !default;

.lc-button-container {
  gap: var(--global-space-m);
  @include flexbox;

  @include sm-min {
    gap: var(--global-space-l);
  }
}

.lc-button {
  background-color: var(--global-color-white-20);
  border-radius: 10px;
	border: 2px solid var(--global-color-black-100);
  color: var(--global-color-black-100);
  cursor: pointer;
  display: inline-block;
  padding: 10px var(--global-space-m);
  text-decoration: none;
  transition: all .2s ease-in-out;

  @include sm-min {
    padding: 14px 22px;
  }

  @include lg-min {
    padding: var(--global-space-m) 27px;
  }

  &:hover {
    background-color: var(--global-color-sand-40);

    .lc-component--background-orange &,
    .lc-component--background-yellow &,
    .lc-component--background-blue &,
    .lc-component--background-darkgrey &,
    .lc-component--background-sand &,
    .lc-hero & {
      background-color: var(--global-color-white-40);
    }
  }

  .lc-hero &,
  .lc-component--background-blue &,
  .lc-component--background-darkgrey &,
  .lc-component--background-orange & {
    border-color:  var(--global-color-white-100);
    color: var(--global-color-white-100);
  }

  &.lc-button--filteractive {
    background-color: var(--global-color-white-40);
  }

  &.lc-component--arrow-right,
  &.lc-component--arrow-bottom {
    padding: 9px 6px;

    @include sm-min {
      padding: 12px 10px;
    }

    @include lg-min {
      padding: 14px 12px;
    }
  }

  &.lc-component--arrow-bottom {
    transform: rotateZ(90deg);
    transition: none;
  }

  &.lc-component--arrow-bottom.lc-teaser--animated {
    transform: rotateZ(-90deg);
  }

  &.lc-component--link-button {
    background: none;
    border: none;
    color: var(--global-color-black-60);
    padding: 0;

    &:hover {
      background: none;
      color: var(--global-color-black-100);
    }
  }

  &.hide-mobile {
    @include sm-max {
      display: none;
    }
  }
}

.lc-button--filler {
  height: 0;
  width: 100%;
}

.lc-button__text {
  display: block;
  @include font-size($font-settings-button);

  .lc-component--arrow-right &,
  .lc-component--arrow-bottom & {
    display: none;
  }

  .lc-component--link-button & {
    @include font-size($font-settings-button-link);
  }
}

.lc-button__text--mobile {
  display: block;

  .lc-component--arrow-right &,
  .lc-component--arrow-bottom & {
    display: none;
  }

 @include sm-min {
    display: none;
 }

 & + .lc-button__text--desktop {
  display: none;

  @include sm-min {
    display: block;

    .lc-component--arrow-right &,
    .lc-component--arrow-bottom & {
      display: none;
    }
  }
 }
}

.lc-button__icon {
  display: none;

  & > a {
    color: var(--global-color-black-100);
    text-decoration: none;

    .lc-hero &,
    .lc-component--background-blue &,
    .lc-component--background-darkgrey &,
    .lc-component--background-orange & {
      color: var(--global-color-white-100);
    }

    .lc-component--link-button & {
      color: var(--global-color-black-60);
    }
  }

  .lc-component--arrow-right &,
  .lc-component--arrow-bottom & {
    display: block;
    height: 24px;
    width: 30px;

    @include sm-min {
      height: 28px;
      width: 32px;
    }

    @include lg-min {
      height: 32px;
      width: 36px;
    }
  }

  svg {
    height: 100%;
    width: 100%;
  }
}
