.iz-content__content {
  overflow-x: hidden;
  @include flexbox($align-content: stretch);

  & > .lc-tag:first-child,
  & > .lc-title-h1:first-child {
    padding-top: 85px;

    @include sm-min {
      padding-top: 95px;
    }
  }
}

.iz-content__content--404 {
  margin-bottom: var(--global-space-xxl);
}
