.lc-questions-container {
  width: 100vw;
}

.lc-questions-item {
  width: 100%;
  @include flexbox($align-content: stretch);

  &.positioned {
    display: none;
    position: absolute;
  }

  &.lc-questions-item-active {
    display: flex;
  }

  .lc-teaser__logo {
    display: none;
  }
}

.lc-questions-item-4 {
 .lc-component--background-darkgrey,
 .lc-component--background-sand {
    .lc-teaser__logo {
      background-image: url('#{$svg-path}/icon-nswow-black.min.svg');
      background-repeat: no-repeat;
      background-size: auto 24px;
      content: "";
      display: block;
      height: 24px;
      width: 120px;

      @include sm-min {
        background-size: auto 30px;
        height: 29px;
        width: 147px;
      }
    }
 }

 .lc-component--background-darkgrey {
  .lc-teaser__logo {
    background-image: url('#{$svg-path}/icon-nspublish-white.min.svg');
    background-size: auto 37px;
    height: 37px;
    width: 159px;

    @include sm-min {
      background-size: auto 47px;
      height: 47px;
      width: 200px;
    }
  }
  }
}
