$font-settings-paragraph: (
  null: (1.6rem, 2.2rem),
  sm: (1.7rem, 2.3rem),
  xxl: (1.8rem, 2.4rem)
) !default;

.lc-paragraph {
  color: var(--global-body-color);
  @include font(var(--global-body-font-family), var(--global-body-font-weight-light));
  @include font-size($font-settings-paragraph);

  .lc-component--background-blue &,
  .lc-component--background-orange &,
  .lc-component--background-darkgrey & {
    color: var(--global-color-white-100);
  }

  .lc-teaser__paragraphs & {
    max-width: 100%;
    padding: 0;
    width: 100%;
  }
}
