$font-settings-quote: (
  null: (2rem, 2.5rem),
  sm: (2.6rem, 3.2rem),
  xxl: (2.6rem, 3.2rem)
) !default;

.lc-quote__quote,
.lc-quote__author {
  color: var(--global-color-black-100);
  @include font(var(--global-body-font-family), var(--global-font-weight-regular));
  @include font-size($font-settings-quote);
}

.lc-quote__quote {
  &::before,
  &:after {
    display: inline-block;
  }

  &:before {
    content: "«";
  }

  &:after {
    content: "»";
  }
}

.lc-quote__author {
  color: var(--global-color-primary-100);
}
