:root {
  @each $breakpoints, $scopes, $components in $theme {
    @each $breakpoint in $breakpoints {
      @if $breakpoint == null {
        @each $scope, $components in $scopes {
          @each $component, $attributes in $components {
            @each $attribute, $value in $attributes {
              --#{$scope}-#{$component}-#{$attribute}: #{$value};
            }
          }
        }
      } @else if $breakpoint != print {
        @include mq-min-print(map-get($layout-breakpoints, $breakpoint)) {
          @if $is-web == true {
            @each $scope, $components in $scopes {
              @each $component, $attributes in $components {
                @each $attribute, $value in $attributes {
                  --#{$scope}-#{$component}-#{$attribute}: #{$value};
                }
              }
            }
          }
        }
      } @else {
        @media print {
          @if $is-pdf == true {
            @each $scope, $components in $scopes {
              @each $component, $attributes in $components {
                @each $attribute, $value in $attributes {
                  --#{$scope}-#{$component}-#{$attribute}: #{$value};
                }
              }
            }
          }
        }
      }
    }
  }
}

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: var(--global-body-font-family);
  font-size: var(--global-body-font-size);
  font-weight: var(--global-body-font-weight);
  line-height: var(--global-body-line-height);
  color: var(--global-body-color);

  &.nav-open {
    overflow: hidden;
  }
}

/*
  Images
*/
img {
  max-width: 100%;
  height: auto;
  display: block;
  image-rendering: -webkit-optimize-contrast;

  &.lazy {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease;

    @media (prefers-reduced-motion: reduce) {
      transition-duration: 0s;
    }
  }

  &.loaded {
    opacity: 1;
    visibility: visible;
  }
}


/*
  Bold styles
*/
.ns-style-bold,
.bold,
.ns-bold,
strong,
b {
  font-weight: var(--global-font-weight-book);
}

/*
  Highlight styles
*/
.ns-highlight {
  color: var(--global-color-primary-100);
}

.mms-italic {
  font-style: italic;
}


/*
  Subscripts and superscripts
*/
.subscript,
sub,
.superscript,
sup {
  font-size: 60%;
  font-variant-numeric: tabular-nums;
  line-height: 60%;
}

.subscript,
sub {
  vertical-align: sub;
}

.superscript,
sup {
  vertical-align: super;
  color: var(--global-color-black-100);
}

/*
  Nowrap's
*/
span.ns-nowrap,
span.ns-key-figure {
  white-space: nowrap;
}

.no-focus {
  *:focus {
    outline: 0;
  }
}


.hide-button .lc-button{
  display: none;
}