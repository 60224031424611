$font-settings-tag: (
  null: (2rem, 2.2rem),
  sm: (2.2rem, 2.6rem),
  xxl: (2.3rem, 2.7rem)
) !default;

.lc-tag,
.lc-teaser__tag {
  color: var(--global-body-color);
  position: relative;
  z-index: 10;
  @include font(var(--global-body-font-family), var(--global-body-font-weight-semilight), 'italic');
  @include font-size($font-settings-tag);

  .lc-grid__width--100 &,
  .lc-teaser--image-video & {
    @include lg-min {
      max-width: calc(49.8% + var(--global-space-xl));
      width: calc(49.8% + var(--global-space-xl));
    }
  }

  &::after {
    background-color: var(--global-color-primary-100);
    content: "";
    display: block;
    height: 2px;
    margin-top: 25px;
    width: 45px;

    @include sm-min {
      width: 50px;
    }

    .lc-component--background-blue &,
    .lc-component--background-orange & {
      background-color: var(--global-color-white-100);
    }

    .lc-component--background-yellow & {
      background-color: var(--global-color-black-100);
    }

    .tag-line-color-black & {
      background-color: var(--global-color-black-100);
    }

    .tag-line-color-orange &,
    .lc-component--background-darkgrey & {
      background-color: var(--global-color-primary-100);
    }

    .tag-line-color-white & {
      background-color: var(--global-color-white-100);
    }
  }

  .lc-icon__tag--nswow &,
  .lc-icon__tag--nspublish & {
    background-image: url('#{$svg-path}/icon-nspublish-black.min.svg');
    background-repeat: no-repeat;
    background-size: auto 24px;
    text-indent: -9999px;

    @include sm-min {
      background-size: auto 30px;
    }
  }

  .lc-icon__tag--nswow & {
    background-image: url('#{$svg-path}/icon-nswow-black.min.svg');
    background-position: left 4px;
    background-size: auto 16px;

    @include sm-min {
      background-position: left 5px;
      background-size: auto 20px;
    }
  }

  .lc-component--background-blue &,
  .lc-component--background-orange &,
  .lc-component--background-darkgrey & {
    color: var(--global-color-white-100);
  }

  .lc-component--background-blue.lc-icon__tag--nspublish &,
  .lc-component--background-orange.lc-icon__tag--nspublish &,
  .lc-component--background-darkgrey.lc-icon__tag--nspublish & {
    background-image: url('#{$svg-path}/icon-nspublish-white.min.svg');
  }

  .lc-component--background-blue.lc-icon__tag--nswow &,
  .lc-component--background-orange.lc-icon__tag--nswow &,
  .lc-component--background-darkgrey.lc-icon__tag--nswow & {
    background-image: url('#{$svg-path}/icon-nswow-white.min.svg');
  }

  .lc-hero__content & {
    color: var(--global-color-white-100);

    @include sm-min {
      margin-top: -33px;
    }
  }
}
