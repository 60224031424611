$font-settings-teaser-contact: (
  null: (2rem, 2.6rem),
  sm: (2.2rem, 2.8rem),
  xxl: (2.5rem, 3rem)
) !default;

.lc-teaser__name,
.lc-teaser__position {
  display: block;
  font-style: normal;
}

.lc-teaser__name,
.lc-teaser__position,
.lc-teaser__paragraph,
.lc-teaser__mail {
  .lc-teaser--contact & {
    @include font-size($font-settings-teaser-contact);
  }
}

.lc-teaser__position {
  font-weight: var(--global-font-weight-semilight);
}

.lc-teaser__name {
  font-weight: var(--global-font-weight-book);
}
