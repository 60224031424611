.lc-hero {
  @include ld-margin-bottom(var(--global-space-l), '.lc-tag');
}

.lc-title-h1,
.lc-title-h2,
.lc-image,
.lc-quote,
.lc-button-container,
.lc-image-container {
  @include ld-margin-bottom(var(--global-space-l));

  @include sm-min {
    @include ld-margin-bottom(var(--global-space-xl));
  }

  .lc-teaser__images &,
  .lc-hero & {
    @include ld-margin-bottom(var(--global-space-reset));
  }

  .lc-teaser--project & {
    @include ld-margin-bottom(var(--global-space-l));
  }
}

.lc-title-h1,
.lc-title-h2 {
  @include ld-margin-bottom(var(--global-space-m), '.lc-lead');

  @include lg-min {
    @include ld-margin-bottom(var(--global-space-l), '.lc-lead');
  }
}

.lc-title-h2 {
  @include ld-margin-bottom(var(--global-space-s), '.lc-paragraph');
  @include ld-margin-bottom(var(--global-space-s), '.lc-list-group ');
  @include ld-margin-bottom(var(--global-space-m), '.lc-title-h3');

  @include lg-max {
    @include ld-margin-bottom(var(--global-space-xl), '.lc-hero__buttons');
  }
}

.lc-title-h3 {
  @include ld-margin-bottom(var(--global-space-s));

  @include sm-min {
    @include ld-margin-bottom(12px);
  }

  @include lg-min {
    @include ld-margin-bottom(var(--global-space-s));
  }
}

.lc-lead {
  @include ld-margin-bottom(var(--global-space-l));

  @include lg-min {
    @include ld-margin-bottom(var(--global-space-xl));
  }
}

.lc-paragraph,
.lc-list-group {
  @include ld-margin-bottom(var(--global-space-l));
  @include ld-margin-bottom(var(--global-space-xl), '.lc-image');
  @include ld-margin-bottom(var(--global-space-l), '.lc-teaser-start');

  @include sm-min {
    @include ld-margin-bottom(var(--global-space-xl), '.lc-teaser-start');
  }

  .lc-teaser--project & {
    @include ld-margin-bottom(var(--global-space-reset));
  }

  .lc-teaser--keyfigure & {
    @include ld-margin-bottom(var(--global-space-l), '.lc-image');
  }
}

.lc-list-group__item {
  @include ld-margin-bottom(var(--global-space-xs));
}


.lc-button-container {
  @include ld-margin-bottom(var(--global-space-l), '.lc-button-container');
  @include ld-margin-bottom(var(--global-space-l), '.lc-paragraph');
}

.lc-footnote {
  @include ld-margin-bottom(var(--global-space-l));
  @include ld-margin-bottom(var(--global-space-s), '.lc-footnote');

  @include sm-min {
    @include ld-margin-bottom(var(--global-space-xl));
  }
}

.lc-tag,
.lc-teaser__tag,
.lc-teaser__logo {
  @include ld-margin-bottom(25px);
  @include ld-margin-bottom(var(--global-space-l), '.lc-title-h1');
  @include ld-margin-bottom(var(--global-space-l), '.lc-title-h2');
  @include ld-margin-bottom(var(--global-space-reset), '.lc-slider__animation');

  @include lg-min {
    @include ld-margin-bottom(var(--global-space-xl), '.lc-title-h1');
    @include ld-margin-bottom(var(--global-space-xl), '.lc-title-h2');
  }
}

.lc-teaser__title {
  @include ld-margin-bottom(var(--global-space-xl));
  @include ld-margin-bottom(var(--global-space-m), '.lc-teaser__images');

  @include sm-min {
    @include ld-margin-bottom(var(--global-space-l), '.lc-teaser__images');
  }

  @include lg-min {
    @include ld-margin-bottom(var(--global-space-xl), '.lc-teaser__images');
  }

  .lc-grid__height--50.lc-grid__width--25 &,
  .lc-teaser--wrapper & {
    @include ld-margin-bottom(var(--global-space-m), '.lc-teaser__paragraph');
  }

  .lc-teaser--paragraph & {
    @include ld-margin-bottom(var(--global-space-l), '.lc-teaser__paragraph');
  }
}

.lc-teaser__images {
  @include sm-min {
    @include ld-margin-bottom(var(--global-space-l));
  }
}

.lc-teaser__paragraph {
  @include ld-margin-bottom(var(--global-space-l), '.lc-teaser__paragraphs');

  .lc-teaser--contact & {
    @include ld-margin-bottom(var(--global-space-reset));
  }
}

.lc-teaser__text {
  @include ld-margin-bottom(var(--global-space-xl), '.lc-teaser__media');

  .image-right & {
    @include sm-max {
      @include ld-margin-bottom(var(--global-space-l), '.lc-teaser__media');
    }
  }
}

.lc-image__image-container {
  @include ld-margin-bottom(10px, '.lc-image__caption');
}

.lc-image {
  .lc-image-container & {
    @include ld-margin-bottom(var(--global-space-reset));
  }
}

.lc-spacer {
  @include ld-margin-bottom(var(--global-space-reset));

  &.lc-spacer-xs {
    margin-top: var(--global-space-xs);
  }

  &.lc-spacer-sm {
    margin-top: var(--global-space-s);
  }

  &.lc-spacer-md {
    margin-top: var(--global-space-m);
  }

  &.lc-spacer-lg {
    margin-top: var(--global-space-l);
  }

  &.lc-spacer-xl {
    margin-top: var(--global-space-xl);
  }

  &.lc-spacer-xxl {
    margin-top: var(--global-space-xxl);
  }
}

.lc-component.lc-teaser__button {
  @include sm-max {
    @include ld-margin-bottom(var(--global-space-l), '.lc-teaser__scroll');
  }
}
