$font-settings-link: (
  null: (2rem, 2.8rem),
  sm: (2rem, 2.8rem),
  xxl: (2.4rem, 3rem)
) !default;

.iz-navigation {
  display: none;

  @include sm-min {
    display: block;
  }

  .nav-open & {
    display: none;
  }
}

.iz-navigation__menu {
  gap: var(--global-space-l);
  list-style: none;
  margin: 0;
  padding: 0;

  @include flexbox($align-content: center);

  @include lg-min {
    gap: var(--global-space-xl);
  }
}

.iz-navigation__link {
  color: var(--global-color-white-100);
  text-decoration: none;

  @include font(var(--global-body-font-family), var(--global-body-font-weight-light));
  @include font-size($font-settings-link);

  &::after {
    border-bottom: 2px solid var(--global-color-primary-100);
    content: "";
    display: block;
    height: 0;
    left: 1px;
    opacity: 0;
    position: relative;
    top: -2px;
    transition: opacity .2s ease-in-out;
    width: calc(100% - 2px);

    .iz-header--scrolled.iz-header--menu-white &,
    .iz-header--scrolled &,
    .iz-header--black & {
      border-bottom: 2px solid var(--global-color-primary-100);
    }

    .iz-header--menu-white & {
      border-bottom: 2px solid var(--global-color-white-100);
    }
  }

  &.active,
  &:hover {
    &::after {
      opacity: 1;
    }
  }

  .iz-header--scrolled &,
  .iz-header--black & {
    color: var(--global-color-black-100);
  }
}
