$font-settings-popup-item: (
  null: (2.8rem, 3.4rem),
  sm: (5.4rem, 6.8rem),
  xxl: (5.4rem, 6.8rem)
) !default;

$font-settings-contact-item: (
  null: (2rem, 2.6rem),
  sm: (2.2rem, 2.9rem),
  xxl: (2.6rem, 3.2rem)
) !default;

.iz-popup {
  background-color: var(--global-color-black-100);
  display: none;
  height: 100%;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  z-index: 100;

  .nav-open & {
    display: block;
  }
}

.iz-popup__container {
  min-height: 100dvh;
  padding: var(--global-space-l) 0;
  width: 100%;
  @include flexbox($align-content: center, $direction: column, $justify-content: center);

  @include lg-min {
    padding: 100px 0;
  }
}

.iz-popup__menu {
  padding: var(--global-space-l) 0;
  text-align: center;
}

.iz-popup__item > a {
  color: var(--global-color-white-100);
  text-decoration: none;
  transition: all .2s ease-in-out;
  @include font(var(--global-body-font-family), var(--global-font-weight-medium));
  @include font-size($font-settings-popup-item);

  &:hover {
    color: var(--global-color-primary-100);
  }
}

.iz-popup__item:nth-child(n+4) > a {
  color: var(--global-color-sand-100);
  opacity: .8;

  &:hover {
    color: var(--global-color-primary-100);
    opacity: 1;
  }
}

.iz-popup__dist,
.iz-popup__language {
  gap: var(--global-space-l);
  height: var(--global-space-l);
  flex: 1 1 auto;
  @include flexbox($align-content: flex-end);
}

.iz-popup__contact-link {
  color: var(--global-color-white-100);
  text-decoration: none;
  transition: all .2s ease-in-out;
  @include font(var(--global-body-font-family), var(--global-font-weight-semilight));
  @include font-size($font-settings-language-link);

  &:hover {
    color: var(--global-color-primary-100);
  }
}
