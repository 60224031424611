.iz-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 110;
}

.iz-header--scrolled,
.iz-header--black {
  background-color: var(--global-color-white-100);

  .nav-open & {
    background-color: transparent;
    transition: none;
  }
}

.iz-header--transition {
  transition: all .4s ease-in-out;
}

.iz-header__logo-nav-container,
.iz-header__nav-container {
  @include flexbox($justify-content: space-between, $align-content: center);
}

.iz-header__logo-link {
  display: block;
  height: 32px;

  @include sm-min {
    height: 41px;
  }

  @include lg-min {
    height: 52px;
  }
}

.iz-header__logo-svg {
  color: var(--global-color-white-100);
  height: 32px;
  width: 95px;

  @include sm-min {
    height: 41px;
    width: 120px;
  }

  @include lg-min {
    height: 52px;
    width: 150px;
  }

  .iz-header--scrolled &,
  .iz-header--black & {
    color: var(--global-color-black-100);
  }

  .nav-open & {
    color: var(--global-color-white-100);
  }
}

.iz-header__inner {
  padding: 19px var(--global-space-m);

  @include sm-min {
    padding: 30px var(--global-space-l) 29px;
  }

  @include lg-min {
    padding: 24px var(--global-space-xl);
  }

  .iz-header--scrollbar & {
    .nav-open & {
      width: calc(100% - 15px);
    }
  }
}

.iz-header__nav-container {
  gap: var(--global-space-xl);

  @include lg-min {
    gap: var(--global-space-xxl);
  }
}

.iz-header__burger-button {
  background-color: transparent;
  border: none;
  color: var(--global-color-primary-100);
  cursor: pointer;
  height: 32px;
  position: relative;
  text-indent: -99999px;
  width: 40px;

  span {
    background-color: var(--global-color-primary-100);
    height: 3px;
    left: 50%;
    position: absolute;
    top: 0;
    transition: width .3s ease-in-out, background-color .3s ease-in-out;
    transform: translateX(-50%);
    width: 30px;

    &:nth-child(1) {
      .nav-open & {
        left: -2px;
        top: 12px;
        transform: rotate(-45deg);
        transition: background-color .3s ease-in-out;
        width: 45px;
      }
    }

    &:nth-child(2) {
      top: 14px;
      width: 40px;

      .nav-open & {
        opacity: 0;
      }
    }

    &:nth-child(3) {
      top: 28px;

      .nav-open & {
        left: -2px;
        top: 12px;
        transform: rotate(45deg);
        transition: background-color .3s ease-in-out;
        width: 45px;
      }
    }
  }

  &:hover {
    span {
      .nav-open & {
        background-color: var(--global-color-white-100);
      }

      width: 40px;
    }
  }

  span {
    .iz-header--menu-white & {
      background-color: var(--global-color-white-100);
    }
  }

  span {
    .iz-header--scrolled &,
    .iz-header--black & {
      background-color: var(--global-color-black-100);
    }
  }

  span {
    .nav-open & {
      background-color: #adadad;
    }
  }
}

.iz-header--black ~ .iz-content {
  margin-top: 70px;

  @include sm-min {
    margin-top: 100px;
  }
}
