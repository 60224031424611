$font-settings-paragraph-keyfigure: (
  null: (2.6rem, 3.2rem),
  sm: (3.6rem, 4.2rem),
  xxl: (4.8rem, 5rem)
) !default;

.lc-teaser__paragraphs{
  @include lg-min {
    max-width: calc(49.8% + var(--global-space-xl));
    width: calc(49.8% + var(--global-space-xl));
  }
}

.lc-teaser__paragraph {
  .lc-teaser--keyfigure & {
    font-weight: var(--global-font-weight-book);
    text-align: center;
    width: 100%;
    @include font-size($font-settings-paragraph-keyfigure);
  }
}

.lc-teaser__paragraph--span {
  display: block;

  .lc-teaser--keyfigure & {
    word-break: break-word;
  }
}

.lc-teaser__paragraph--orange {
  color: var(--global-color-primary-100);

  .lc-component--background-orange & {
    color: var(--global-color-black-100);
  }
}

.lc-teaser__paragraph--bw {
  color: var(--global-color-black-100);

  .lc-component--background-blue &,
  .lc-component--background-orange &,
  .lc-component--background-darkgrey & {
    color: var(--global-color-white-100);
  }
}

.lc-teaser__tag,
.lc-teaser__title,
.lc-teaser__paragraph {
  .lc-teaser--paragraph & {
    @include lg-min {
      max-width: calc(49.8% + var(--global-space-xl));
      width: calc(49.8% + var(--global-space-xl));
    }
  }
}

.lc-teaser__paragraph--add {
  bottom: -74px;
  position: absolute;

  @include sm-min {
    bottom: -92px;
  }
}
