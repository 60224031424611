.lc-case-profile{
  @include ld-margin-bottom(var(--global-space-xl));

  @include md-min{
    @include flexbox(row, nowrap, flex-start, flex-start);
    
    &.revert{
      @include flexbox(row-reverse, nowrap, flex-start, flex-start);
    }
  }

  @include md-min{
    padding: 0 var(--global-space-l);
  }
}
.lc-case-profile__main{
  @include md-min{
    width: 70%;
  }
  @include lg-min{
    width: 60%;
  }
  @include xl-min{
    width: calc(49.8% + var(--global-space-xl));
    margin-left: calc(25.2% - 48px);
    
    .revert > &{
      width: calc(49.8% + var(--global-space-xl));
      margin-left: var(--global-space-l);
      margin-right: calc(25.2% - 22px);
    }
  }
}

.lc-case-profile__sidebar{
  background: var(--global-color-sand-100);
  min-height: 100px;
  padding: var(--global-space-m);
  margin-top: var(--global-space-l);
  @include md-min{
    width: 30%;
    margin-top: 0;
    margin-left: var(--global-space-l);
    .revert > &{
      margin-right: var(--global-space-l);
    }
  }
  @include lg-min{
    width: calc(40% - var(--global-space-l));
    .revert > & {
      margin-left: 0;
    }
  } 
  @include xl-min{
    width: calc(25% - var(--global-space-xl) - var(--global-space-l));

    .revert > & {
      margin-left: 0;
    }
  }

  .lc-title-h3{
    @include ld-margin-bottom(var(--global-space-xs), '.lc-case-profile-item');
  }
  .lc-image{
    @include ld-margin-bottom(var(--global-space-s));
  }
}

.lc-case-profile-item{
  @include flexbox(row, nowrap, space-between, flex-start);
  @include ld-margin-bottom(var(--global-space-s));
  @include ld-margin-bottom(var(--global-space-m), '.lc-title-h3');
}

.lc-case-profile-item__right{
  text-align: right;
  font-style: italic;
}

.lc-case-profile-modules__modules{
  @include flexbox(row, wrap, space-between, flex-start);
}

.lc-case-profile-modules__module{
  width: calc(50% - var(--global-space-s));
  max-width: 200px;

  svg{
    width: 100%;
  }
}

.lc-case-profile-modules__module{
  display: none;
  margin-top: var(--global-space-m);
}

.module-translateplus .lc-case-profile-modules__module--translateplus{
  display: block;
}

.module-taggingplus .lc-case-profile-modules__module--taggingplus{
  display: block;
}

.module-pdfplus .lc-case-profile-modules__module--pdfplus{
  display: block;
}

.module-milestoneplus .lc-case-profile-modules__module--milestoneplus{
  display: block;
}

.module-financeplus .lc-case-profile-modules__module--financeplus{
  display: block;
}

.module-bridgeplus .lc-case-profile-modules__module--bridgeplus{
  display: block;
}