.iz-cookie-toggle {
  background: var(--global-color-sand-100);
  bottom: 8px;
  color: var(--global-color-black-80);
  cursor: pointer;
  border-radius: 50%;
  left: 8px;
  position: fixed;
  z-index: 40;

  @include flexbox($justify-content: center, $align-content: center);
  @include size(40px);

  .svg-cookie {
    @include size(24px);
  }
}
