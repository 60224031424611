span,
.lc-linkable {
  a {
    color: inherit;
    opacity: .4;
    text-decoration: none;
    transition: opacity .2s ease-in-out;

    &:hover {
     opacity: 1;
    }
  }
}
