.lc-teaser--image-video {
  padding-bottom: 0;
  position: relative;
  z-index: 20;

  &.image-right {
    padding-bottom: 134px;

    @include sm-min {
      padding-bottom: 166px;
    }
  }

  .lc-teaser__media,
  .lc-teaser__video {
    height: 100%;
    position: relative;

    @include sm-max {
      position: static;
    }
  }

  video {
    border-radius: 10px;
    height: auto;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.lc-teaser__text {
  .image-right & {
    width: 100%;

    @include lg-min {
      width: 36%;
    }
  }

  & > * {
    .image-right & {
      max-width: 100%;
      width: 100%;
    }
  }
}

.lc-teaser__media,
.lc-teaser__video {
  .image-right & {
    height: auto;
  }
}

.lc-teaser__media {
  .image-right & {
    @include lg-min {
      padding-top: 22px;
      right: 0;
      position: absolute;
      top: 0;
      width: 60%;
    }
  }
}

.lc-teaser__video .lc-video__thumbnail {
  .image-right & {
    background-size: cover;
  }
}

.lc-teaser__tooltip {
  bottom: 14px;
  color: var(--global-color-black-40);
  display: block;
  font-weight: var(--global-font-weight-semilight);
  left: 56px;
  opacity: 0;
  position: absolute;
  transition: opacity 0.3s;
  white-space: nowrap;
  visibility: hidden;



  .lc-teaser__scroll:hover & {
    visibility: visible;
    opacity: 1;
  }
}

.lc-teaser__scroll {
  bottom: var(--global-space-l);
  color: var(--global-color-black-40);
  cursor: pointer;
  display: none;
  left: 50%;
  transform: translateX(-50%);
  transition: .2s ease-in-out;
  position: absolute;
  z-index: 20;

  &:hover {
    opacity: 1;
  }

  @include sm-max {
    display: none;
  }

  .show-scroll-button & {
    display: block;
  }
}
