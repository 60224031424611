$font-settings-h1: (
  null: (3rem, 3.4rem),
  sm: (6rem, 6.7rem),
  xxl: (7.7rem, 8.1rem)
) !default;

$font-settings-h2: (
  null: (2.6rem, 3.2rem),
  sm: (3.6rem, 4.2rem),
  xxl: (5.4rem, 5.7rem)
) !default;

$font-settings-h3: (
  null: (2.2rem, 3rem),
  sm: (2.8rem, 3.5rem),
  xxl: (2.8rem, 3.5rem)
) !default;

$font-settings-h4: (
  null: (1.8rem, 2.4rem),
  sm: (2.4rem, 3rem),
  xxl: (2.4rem, 3rem)
) !default;

$font-settings-h5: (
  null: (1.6rem, 2.2rem),
  sm: (2rem, 2.8rem),
  xxl: (2rem, 2.8rem),
) !default;

$font-settings-h6: (
  null: (1.6rem, 2.2rem),
  sm: (1.7rem, 2.3rem),
  xxl: (1.8rem, 2.4rem)
) !default;

.lc-title-h1 {
  color: var(--global-body-color);
  @include font(var(--global-body-font-family), var(--global-font-weight-medium));
  @include font-size($font-settings-h1);

  .lc-hero__content & {
    color: var(--global-color-primary-100);
    display: block;
  }

  .lc-component--background-orange & {
    color: var(--global-color-black-100);
  }
}

.lc-title-h2 {
  color: var(--global-body-color);
  @include font(var(--global-body-font-family), var(--global-font-weight-book));
  @include font-size($font-settings-h2);

  .lc-hero__content & {
    color: var(--global-color-white-100);
    display: block;
    @include font-size($font-settings-h1);
  }

  .lc-component--background-sand &,
  .lc-component--background-yellow & {
    color: var(--global-color-black-100);
  }
}

.lc-title-h3 {
  color: var(--global-body-color);
  @include font(var(--global-body-font-family), var(--global-font-weight-book));
  @include font-size($font-settings-h3);
}

.lc-title-h4 {
  color: var(--global-body-color);
  @include font(var(--global-body-font-family), var(--global-font-weight-book));
  @include font-size($font-settings-h4);
}

.lc-title-h5 {
  color: var(--global-body-color);
  @include font(var(--global-body-font-family), var(--global-font-weight-book));
  @include font-size($font-settings-h5);
}

.lc-title-h6 {
  color: var(--global-body-color);
  @include font(var(--global-body-font-family), var(--global-font-weight-book));
  @include font-size($font-settings-h6);
}

.lc-title--span {
  display: block;
}

.lc-title--orange {
  color: var(--global-color-primary-100);
}
