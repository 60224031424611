$font-settings-slider-text: (
  null: (1.9rem, 2.2rem ),
  xxl: (3rem, 3.8rem),
  print: (28pt, 32.5pt)
);

.lc-slider {
  max-width: 100vw;
  min-height: calc(100dvh - 70px);
  padding: var(--global-space-l) 0;
  width: 100vw;

  @include sm-min {
    min-height: calc(100dvh - 100px);
  }

  @include lg-min {
    min-height: calc(100dvh - 100px);
  }
}
.lc-slider__tag {
  padding: 0 var(--global-space-m);

  @include sm-min {
    padding: 0 var(--global-space-l);
  }

  @include lg-min {
    padding: 0 var(--global-space-xl);
  }
}

.lc-slider__animation {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  & > video {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.lc-slider-container {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  flex-shrink: 0;
  overflow: hidden;
  padding: var(--global-space-m) var(--global-space-m) 132px;
  position: relative;
  width: 100%;
  @include flexbox($justify-content: space-between, $direction: column);

  @include sm-min {
    padding: var(--global-space-l) var(--global-space-l) 172px;
  }

  @include lg-min {
    padding: var(--global-space-xl) var(--global-space-xl) 172px;
  }
}

.lc-slider-row {
  width: 100%;
  @include flexbox($justify-content: space-between, $align-content: center);

  &:nth-child(2n+2) {
    @include sm-min {
      margin: 0 auto;
      width: 82%;
    }

    @include sm-max {
      & > .lc-slider-item {
        flex-basis: 100%;
        justify-content: center;
        width: 100%;

        & > figure {
          width: 100%;
        }
      }
    }
  }
}

.lc-slider-item {
  min-height: 85px;
  @include flexbox($align-content: center);

  @include sm-min {
    min-height: 125px;
  }
}
