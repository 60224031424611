@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-HairlineItalic.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-HairlineItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-Hairline.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-Hairline.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-ThinItalic.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-ThinItalic.woff') format('woff');
  font-weight: 150;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-Thin.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-Thin.woff') format('woff');
  font-weight: 150;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-UltralightIt.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-UltralightIt.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-Ultralight.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-Ultralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-ExtralightIt.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-ExtralightIt.woff') format('woff');
  font-weight: 250;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-Extralight.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-Extralight.woff') format('woff');
  font-weight: 250;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-LightItalic.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-Light.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-SemilightItalic.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-SemilightItalic.woff') format('woff');
  font-weight: 350;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-Semilight.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-Semilight.woff') format('woff');
  font-weight: 350;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-RegularItalic.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-Regular.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-BookItalic.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-BookItalic.woff') format('woff');
  font-weight: 450;
  font-style: italic;
  font-display: swap;
}


@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-Book.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-Book.woff') format('woff');
  font-weight: 450;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-Medium.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-MediumItalic.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-Demibold.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-Demibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-DemiboldItalic.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-DemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-Bold.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-BoldItalic.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}


@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-ExtraboldItalic.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-ExtraboldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-Extrabold.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-Extrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-BlackItalic.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Real Head Pro';
  src: url('#{$font-path}/RealHeadPro-Black.woff2') format('woff2'),
      url('#{$font-path}/RealHeadPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
