@mixin ld-margin-bottom($margin: 0, $selector: '*') {
  & + #{$selector} {
    margin-top: $margin;
  }

  & + .lc-pdf-pagebreak + #{$selector},
  & + .lc-anchor + #{$selector} {
    margin-top: $margin;
  }

  & + .lc-pdf-pagebreak,
  & + .lc-spacer {
    margin-top: 0;
  }
}
