.lc-teaser--wrapper {
  max-height: 1500px;
  overflow: hidden;
  padding: 0;
  width: 100%;
  @include flexbox;

  @include sm-max {
    @include flexbox($direction: 'column-reverse', $wrap: nowrap);
  }

  @include lg-min {
    width: 50%;
  }

  &.revert,
  &.reverted {
    @include sm-max {
      @include flexbox($direction: 'column-reverse', $wrap: nowrap);
    }

    @include lg-min {
      @include flexbox($direction: 'row-reverse');
    }
  }

  &.inverted {
    @include lg-min {
      @include flexbox($direction: 'row');
    }
  }

  &.lc-grid__height--50.lc-teaser {
    @include lg-max {
      width: 100%;
    }
  }

  &.reverted--ta,
  &:nth-child(2n+2):not(.reverted--ta):not(.inverted--ta) {
    @include sm-min {
      @include lg-max {
        @include flexbox($direction: 'row-reverse');
      }
    }
  }
}
