$font-settings-footer-text: (
  null: (2rem, 2.6rem),
  sm: (2.2rem, 2.9rem),
  xxl: (2.6rem, 3.2rem)
) !default;

.iz-footer__inner {
  padding: var(--global-space-l) var(--global-space-m);

  @include sm-min {
    padding: var(--global-space-l);
  }

  @include lg-min {
    padding: var(--global-space-xl);
  }
}

.iz-footer__row {
  @include flexbox($align-content: stretch);
  @media (min-width: 1280px) {
    gap: var(--global-space-xl);
  }

  @media (max-width: 1279px) {
    &:last-child {
      gap: var(--global-space-xl);
      margin-top: var(--global-space-l);
      @include flexbox($direction: column-reverse);
    }
  }

  @include sm-max {
    &:last-child {
      gap: var(--global-space-l);
      margin-top: var(--global-space-reset);
    }
  }

  @include xl-min {
    gap: var(--global-space-xxl);
  }
}

.iz-footer__column {
  @include sm-min {
    @media (max-width: 1279px) {
      &:first-child {
        flex: 1 1 auto;
      }

      &:nth-child(2) {
        flex: 0 0 auto;
      }

      &:nth-child(3) {
        flex: 1 1 100%;
      }
    }
  }

  @include sm-max {
    flex: 1 1 100%;
    @include ld-margin-bottom(var(--global-space-l));
  }

  @media (min-width: 1280px) {
    &:nth-child(2) {
      flex: 1;
    }
  }

  &:first-child {
    .iz-footer__row:last-child & {
      flex-basis: 100%;
      @include flexbox($justify-content: flex-end, $direction: column);

      @media (min-width: 1280px) {
        flex: 1 0;
      }
    }
  }
}

.iz-footer__language-switcher {
  gap: var(--global-space-l);
  @include flexbox;

  @include sm-min {
    @media (max-width: 1279px) {
      margin: var(--global-space-xl) 0;
    }
  }
}

.iz-footer__address,
.iz-footer__copyright,
.iz-footer__linkedin,
.iz-footer__link,
.iz-footer__social-icons {
  font-style: normal;
  line-height: 1.5;

  @include font(var(--global-body-font-family), var(--global-body-font-weight-light));
  @include font-size($font-settings-footer-text);
}

.iz-footer__address-title,
.iz-footer__linkedin-text,
.iz-footer__social-icons-text {
  font-weight: var(--global-font-weight-book);
}

.iz-footer__linkedin-link,
.iz-footer__link {
  color: var(--global-color-black-60);
  text-decoration: none;
  transition: color .2s ease-in-out;

  &:hover {
    color: var(--global-color-black-100);
  }
}

.iz-footer__copyright-text,
.iz-footer__linkedin-text {
  margin-right: 6px;
}

.iz-footer__linkedin {
  @include ld-margin-bottom(var(--global-space-m));
}

.iz-footer__social-icons {
  @include ld-margin-bottom(var(--global-space-l));
}

.iz-footer__menu {
  list-style: none;
  padding: 0;

  @media (min-width: 1280px) {
    text-align: right;
  }
}

.iz-footer__menu-item {
  margin: 5px 0;

  a {
    text-decoration: none;
    color: #333;

    &:hover {
      text-decoration: underline;
    }
  }
}

.iz-footer__links {
  @include flexbox($direction: column);

  @media (min-width: 1280px) {
    @include flexbox($direction: column, $align-content: flex-start);
  }
}

.iz-footer__icons {
  @include flexbox($direction: column-reverse);

  @include sm-min {
    @include flexbox($align-content: center, $direction: row-reverse);
  }

  @media (min-width: 1280px) {
    padding-top: var(--global-space-xl);
    @include flexbox($justify-content: flex-end, $align-content: center, $wrap: nowrap);
  }
}

.iz-footer__icon-link {
  &:hover {
    opacity: 0.7;
  }
}

.iz-footer__icon {
  width: auto;

  &.iz-footer__icon--iia {
    background-image: url('#{$svg-path}/icon-iia-black.min.svg');
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    height: 75px;
    width: 246px;

    @include sm-max {
      margin-left: -3px;
    }

    @include sm-min {
      height: 90px;
      width: 300px;
    }

    @media (min-width: 1280px) {
      height: 117px;
      width: 389px;
    }
  }

  &.iz-footer__icon--xbrl {
    background-image: url('#{$svg-path}/icon-xbrl-black.min.svg');
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    margin-top: var(--global-space-m);
    height: 49px;
    width: 145px;

    @include sm-min {
      margin-top: 0;
      margin-left: var(--global-space-l);
      height: 58px;
      width: 171px;
    }

    @media (min-width: 1280px) {
      margin-left: 0;
      margin-right: 40px;
      height: 82px;
      width: 242px;
    }
  }
}

.iz-footer__social-icons-container {
  gap: var(--global-space-m);
  margin-top: var(--global-space-s);
  @include flexbox(nowrap, row, flex-start, flex-start);
}

.iz-footer__social-icons-svg {
  .iz-footer__social-icons-link & {
    color: var(--global-color-black-60);
    transition: color .2s ease-in-out;
  }

  .iz-footer__social-icons-link:hover & {
    color: var(--global-color-black-100);
  }
}
