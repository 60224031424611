.lc-image,
.lc-image__image-container,
.lc-image__img {
  .lc-grid__area & {
    height: 100%;
    width: 100%;
  }
}

.lc-image-container {
  gap: var(--global-space-l);
  @include flexbox;

  @include sm-min {
    gap: var(--global-space-xl);
  }
}

.lc-image {
  .lc-teaser__images & {
    padding: 0;
    width: auto;
  }

  .lc-teaser--project & {
    flex-grow: 1;
    position: relative;
    width: 100%;
  }

  .lc-teaser--keyfigure & {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    max-height: 105px;
  }

  .lc-image-container & {
    max-width: fit-content;
    padding-left: 0;
    padding-right: 0;
    width: auto;
  }
}

.lc-image__img {
  .lc-teaser__images & {
    max-height: 117px;
    width: auto;
  }

  .lc-teaser--image & {
    height: 100%;
    position: absolute;
    object-fit: cover;
    width: 100%;
  }

  .lc-teaser--project & {
    height: 100%;
    left: 50%;
    object-fit: contain;
    position: absolute;
    transform: translateX(-50%);
  }

  .lc-teaser--keyfigure & {
    height: 100%;
    object-fit: contain;
  }

  .lc-teaser--wrapper-contact & {
    object-position: top;
  }

  .lc-slider-item & {
    height: auto;
    margin: 0 auto;
    max-height: 60px;
    max-width: 120px;
    width: 100%;

    @include sm-min {
      max-height: 75px;
      max-width: 140px;
    }
  }

  .image-contain & {
    object-fit: contain;
  }

  .image-small & {
    object-fit: contain;
    left: 50%;
    max-height: 160px;
    max-width: 250px;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .max-height & {
    max-height: 600px;
  }
}

.lc-image__caption {
  color: var(--global-color-black-60);
}
