.lc-hero-slider {
  min-height: 100dvh;
  width: 100vw;

  .background-gradient & {
    background: linear-gradient(90deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.lc-hero-slider__wrapper {
  height: auto;
}

.lc-hero-slider__controls {
  bottom: var(--global-space-l);
  gap: var(--global-space-m);
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
  z-index: 10;
  @include flexbox($align-content: center, $justify-content: center);

  gap: var(--global-space-s);

  @include sm-min {
    gap: var(--global-space-m);
  }
}

.lc-hero-slider__playpause {
  cursor: pointer;
  display: none !important;
  height: 36px;
  position: relative;
  width: 36px;

  circle {
    transition: all .2s ease-in-out;
  }

  &:hover {
    circle {
      fill-opacity: .4;
    }
  }
}

.lc-hero-slider__svg {
  color: var(--global-color-white-100);
  left: 0;
  position: absolute;
  top: 0;
}

.lc-hero-slider__svg--pause {
  display: block;

  .paused & {
    display: none;
  }
}

.lc-hero-slider__svg--play {
  display: none;

  .paused & {
    display: block;
  }
}
