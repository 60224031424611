$font-settings-list: (
  null: (1.6rem, 2.2rem),
  sm: (1.7rem, 2.3rem),
  xxl: (1.8rem, 2.4rem)
) !default;


.lc-list-group {
  list-style: none;
}

.lc-list-group--ol {
  counter-reset: ordered-list;
}

.lc-list-group--alphanumeric {
  counter-reset: alphanumeric-list;
}


.lc-list-group__item {
  color: var(--global-body-color);
  @include flexbox(row, nowrap, flex-start, flex-start);
  @include font(var(--global-body-font-family), var(--global-body-font-weight-light));
  @include font-size($font-settings-list);

  &:before {
    display: block;
    flex: 0 0 var(--global-space-l);
    width: var(--global-space-l);

    .lc-list-group--ul & {
      content: "—";
      flex: 0 0 26px;
      width: 26px;
    }

    .lc-list-group--ol & {
      counter-increment: ordered-list;
      content: counter(ordered-list) ".";
    }

    .lc-list-group--alphanumeric & {
      counter-increment: alphanumeric-list;
      content: counter(alphanumeric-list, lower-alpha) ".";
    }
  }

  &.list-indented {
    margin-left: 46px;
  }
}
