$font-settings-language-link: (
  null: (2rem, 2.6rem),
  sm: (2.2rem, 2.9rem),
  xxl: (2.6rem, 3.2rem)
) !default;

.iz-language-switcher__link {
  color: var(--global-color-white-100);
  text-decoration: none;
  transition: all .2s ease-in-out;
  @include font(var(--global-body-font-family), var(--global-font-weight-semilight));
  @include font-size($font-settings-language-link);

  &:hover {
    color: var(--global-color-primary-100);
  }

  .iz-footer__language-switcher & {
    color: var(--global-color-black-100);
    font-weight: var(--global-font-weight-semilight);
  }
}

.iz-language-switcher__link--active {
  .iz-footer__language-switcher & {
    font-weight: var(--global-font-weight-book);
  }
}
