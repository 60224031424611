$font-settings-lead: (
  null: (2.4rem, 3.2rem),
  sm: (2.8rem, 3.6rem),
  xxl: (3.2rem, 3.9rem)
) !default;

.lc-lead {
  color: var(--global-body-color);
  @include font(var(--global-body-font-family), var(--global-body-font-weight-light));
  @include font-size($font-settings-lead);
}
