.lc-component--background-blue {
  background-color: var(--global-color-secondary-100);
}

.lc-component--background-blue-20 {
  background-color: var(--global-color-secondary-20);
}

.lc-component--background-yellow {
  background-color: var(--global-color-yellow-100);
}

.lc-component--background-orange {
  background-color: var(--global-color-primary-100);
}

.lc-component--background-darkgrey {
  background-color: var(--global-color-black-80);
}

.lc-component--background-sand {
  background-color: var(--global-color-sand-100);
}

.lc-component--background-sand-20 {
  background-color: var(--global-color-sand-20);
}

.lc-component--background-sand-40 {
  background-color: var(--global-color-sand-40);
}

.lc-component--background-sand-60 {
  background-color: var(--global-color-sand-60);
}


.lc-component--padding {
  max-width: 100%;
  padding-left: var(--global-space-m);
  padding-right: var(--global-space-m);
  width: 100%;

  @include sm-min {
    padding-left: var(--global-space-l);
    padding-right: var(--global-space-l);
    margin-left: auto;
    margin-right: auto;
  }

  @include lg-min {
    max-width: calc(49.8% + var(--global-space-xl));
    padding-left: 0;
    padding-right: 0;
    width: calc(49.8% + var(--global-space-xl));
  }

  .svg-infografik-popup & {
    padding-left: 0;
  }
}

.lc-component--wide{
  max-width: 100%;
  padding-left: var(--global-space-m);
  padding-right: var(--global-space-m);
  width: 100%;

  @include sm-min {
    padding-left: var(--global-space-l);
    padding-right: var(--global-space-l);
    margin-left: auto;
    margin-right: auto;
  }

  @include lg-min {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}

.lc-component--padding.screen-width {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;

  .lc-image__img{
    width: 100%;
  }
}

.lc-case-profile__sidebar,
.lc-case-profile__main {
  & > .lc-component--padding{
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}