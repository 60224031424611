$font-settings-infografik-title: (
  null: (2.4rem, 3.2rem),
  xxl: (3.2rem, 4.1rem)
) !default;

$font-settings-infografik-text: (
  null: (2rem, 2.6rem),
  xxl: (2.6rem, 3.2rem)
) !default;

.svg-infografik__wrapper {
  height: 100%;
  width: 100%;

  overflow-y: hidden;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.svg-infografik--disabled {
  opacity: .5;
}

// .svg-infografik--mobile {
//   display: block;

//   @include lg-min {
//     display: none;
//   }
// }

.svg-infografik {
  .lc-video__video & {
    min-width: 960px;
    padding-bottom: var(--global-space-xl);
  }
}

.svg-infografik-popup {
  background-color: var(--global-color-white-100);
  border-radius: 10px;
  box-shadow: 0px 0px 15px 0px rgba(230, 230, 220, 0.70);
  display: none;
  left: 50%;
  max-width: 1000px;
  padding: var(--global-space-l) 79px var(--global-space-l) var(--global-space-l);
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;

  &.svg-infografik-popup--active {
    display: block;
  }

  .lc-lead {
    font-weight: var(--global-font-weight-book);
    @include font-size($font-settings-infografik-title);
    @include ld-margin-bottom(var(--global-space-m));
  }

  .lc-paragraph,
  .lc-list-group__item {
    @include font-size($font-settings-infografik-text);
    @include ld-margin-bottom(var(--global-space-s));
  }
}

.svg-infografik-button,
.svg-infografik-href {
  cursor: pointer;

  circle,
  rect {
    transition: .2s fill ease-in-out;
  }

  &:hover {
    circle,
    rect {
      fill: #949493 !important;
    }

    .startpage & {
      circle,
      rect {
        fill: var(--global-color-white-40) !important;
      }
    }
  }
}

.svg-infografik-href {
  &:hover {
    circle,
    rect {
      fill: var(--global-color-white-40) !important;
    }
  }
}

.svg-infografik-button--sand {
  &:hover {
    .startpage & {
      circle,
      rect {
        fill: var(--global-color-sand-40) !important;
      }
    }
  }
}

.svg-infografik--close {
  cursor: pointer;
  margin-top: 0 !important;
  right: var(--global-space-l);
  position: absolute;
  top: var(--global-space-l);

  @include size(15px);
}
