$font-settings-teaser-project-p: (
  null: (1.6rem, 2.2rem),
  sm: (1.8rem, 2.4rem)
) !default;

.lc-teaser--project {
  max-height: 1500px;
  overflow: hidden;
  padding-bottom: var(--global-space-l);

  @include lg-max {
    &.lc-teaser.lc-grid__height--50 {
      width: 100%;
    }
  }

  .lc-teaser__paragraph {
    @include font-size($font-settings-teaser-project-p);
  }
}

@include lg-max {
  .lc-teaser--project:not(.lc-component--background-yellow) +
  .lc-teaser--project:not(.lc-component--background-yellow) {
    background-color: var(--global-color-yellow-100);
  }

  .lc-teaser--project:not(.lc-component--background-yellow) +
  .lc-teaser--project:not(.lc-component--background-yellow) +
  .lc-teaser--project.lc-component--background-yellow,
  .lc-teaser--project:not(.lc-component--background-yellow) +
  .lc-teaser--project:not(.lc-component--background-yellow) +
  .lc-teaser--project.lc-component--background-yellow +
  .lc-teaser--project.lc-component--background-yellow +
  .lc-teaser--project:not(.lc-component--background-yellow) {
    background-color: var(--global-color-white-100);
  }
}

